import React from 'react'
import Layout from '../components/layout'
import { MainContainer, SlideH3, SlideTextSmall } from '../styles/common.styled'
import { Section, Heading } from '../styles/default-template.styled'
import {ReferenceContainer, BottomOverlay, Reference} from '../styles/referanser.styled'
import GatsbyImage from 'gatsby-image'
import { Link, graphql } from 'gatsby'
import SEO from '../components/seo'



export const query = graphql`
    query {
        references: allSanityWorkReference {
            nodes {
                _id
                heading
                image {
                    asset {
                        fluid(maxWidth: 400, maxHeight: 400) {
                            ...GatsbySanityImageFluid
                        }
                    }   
                }
                text
                slug {
                    current
                }
            }
        }
    }
`


const ReferanserPage = ({data}) => {

    const seo = {
        description: "Diverse arbeider inne og ute"
    }

    return (
        <Layout>
            <SEO title="Referanser" seo={seo} />
            <Section>
                <MainContainer>
                    <Heading>Referanser</Heading>
                    <ReferenceContainer>
                    {data.references.nodes.map(({slug, heading, image, text, _id}) => (
                        <Reference key={_id}>
                            <Link to={`/portfolio/${slug.current}`}>
                                <GatsbyImage fluid={image.asset.fluid}/>
                                <BottomOverlay>
                                    <SlideH3>{heading}</SlideH3>
                                    <SlideTextSmall>{text}</SlideTextSmall>
                                </BottomOverlay>
                            </Link>

                        </Reference>
                    ))}
                    </ReferenceContainer>
                </MainContainer>
            </Section>
        </Layout>
    )
}

export default ReferanserPage