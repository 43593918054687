import styled from 'styled-components'

export const Reference = styled.div`
    padding: 40px;
    width: 100%;

`
export const ReferenceContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    @media (max-width: 1024px) {
        flex-direction: column;
    }
`

export const BottomOverlay = styled.div`
    height: 100px;
    background-color: #db002e;
    bottom: 0;
    width: 100%;
    padding-left: 40px;
    padding-top: 5px;
`