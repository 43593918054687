import styled from 'styled-components'
import Img from 'gatsby-image'
import { MainContainer } from './common.styled'


export const Section = styled.div`
    padding-top: 50px;
    background-color: rgb(251, 250, 250);

    h3 {
        font-family: Montserrat;
    }


`

export const Heading = styled.h1`
    font-size: 48px;
    line-height: 1.6;
    font-weight: 700;
    display: inline;
    text-transform: none;
    letter-spacing: 0;
    float: none;
    color: #444;
    font-family: Montserrat;
`

export const Text = styled.div`
    margin-top: 10px;
    margin-bottom: 16px;
    line-height: 29.2px;
    font-size: 16px;
    font-family: Ubuntu, sans-serif;
    
`

export const CoverImage = styled(Img)`
    margin-top: 100px;
    width: 100%;

    @media (max-width: 768px) {
        margin-top: 20px;
    }
    
`

export const AboutContainer = styled(MainContainer)`



@media (min-width: 1200px) {
    padding-bottom: 650px;
}


@media (max-width: 1200px) {
    padding-bottom: 350px;
}

@media (max-width: 768px) {
    padding-bottom: 50px;
}


`